<template>
  <q-drawer
    v-model="cartStore.visible"
    :width="screen.lt.lg ? 335 : 400"
    side="right"
    behavior="mobile"
    overlay
  >
    <div class="column fit">
      <q-scroll-area
        v-if="!!cartStore.data?.items?.length"
        class="col"
      >
        <CartDrawerItem
          v-for="item in cartStore.data.items"
          :key="item.id"
          :item="item"
          :disable="cartStore.pending"
          @remove="cartStore.remove($event)"
          @update:item="cartStore.update($event)"
        />

        <div class="column q-gutter-y-sm q-px-sm">
          <q-card
            v-if="cartStore.data?.subTotal && canShowSubtotal"
            square
            bordered
            flat
          >
            <q-card-section :class="altMethodsAvailable ? 'q-pb-sm' : ''">
              <div class="row flex-center q-gutter-sm">
                <div class="text-h5 text-center text-weight-light">
                  {{ currency(cartStore.data.subTotal) }}
                </div>

                <q-avatar
                  :color="dark.isActive ? 'grey-8' : 'white'"
                  icon="img:/brands/visa.svg"
                  font-size="30px"
                  style="width: 45px; height: 32px"
                  rounded
                />

                <q-avatar
                  :color="dark.isActive ? 'grey-8' : 'white'"
                  icon="img:/brands/mastercard.svg"
                  font-size="30px"
                  style="width: 45px; height: 32px"
                  rounded
                />

                <q-avatar
                  :color="dark.isActive ? 'grey-8' : 'white'"
                  icon="img:/brands/discover.svg"
                  font-size="30px"
                  style="width: 45px; height: 32px"
                  rounded
                />
              </div>
            </q-card-section>

            <div
              v-if="altMethodsAvailable"
              class="row q-gutter-x-sm flex-center"
            >
              <q-separator class="col" />
              <div>or</div>
              <q-separator class="col" />
            </div>

            <q-card-section
              v-if="credovaAvailable"
              class="q-pt-sm q-px-sm"
            >
              <LazyCartDrawerFinancing :amount="cartStore.data.subTotal" />
            </q-card-section>

            <q-separator />

            <q-card-section>
              <div class="text-subtitle2">
                Shipping, taxes, and discounts calculated at checkout.
              </div>
            </q-card-section>
          </q-card>

          <lazy-q-banner
            v-else
            class="bg-warning"
          >
            Due to a manufacturer's pricing policy, we cannot show you the
            subtotal. Initiate the checkout process to see your grand total.
            <template #action>
              <q-btn
                label="Proceed to Checkout"
                flat
                @click="onCheckoutClick()"
              />
            </template>
          </lazy-q-banner>

          <q-btn
            :disable="cartStore.pending"
            label="Empty Cart"
            class="full-width"
            outline
            square
            @click="cartStore.clear()"
          />
        </div>
      </q-scroll-area>

      <div
        v-else
        class="col flex-center"
      >
        <div class="column flex-center fit">
          <q-icon
            :name="mdiCartOff"
            size="xl"
            color="grey"
          />
          <div class="text-h5 text-grey">
            Empty cart
          </div>
        </div>
      </div>

      <div class="column q-gutter-y-sm q-pa-sm">
        <q-btn
          v-if="cartStore.data?.items?.length"
          :disable="cartStore.pending"
          label="Proceed To Checkout"
          color="primary"
          size="xl"
          class="full-width"
          square
          @click="onCheckoutClick()"
        />
        <q-btn
          v-else
          label="Continue Shopping"
          color="primary"
          size="lg"
          class="full-width"
          square
          outline
          @click="cartStore.toggle(false)"
        />
      </div>
    </div>

    <q-inner-loading :showing="cartStore.pending" />
  </q-drawer>
</template>

<script lang="ts" setup>
import { mdiCartOff } from '@quasar/extras/mdi-v7';
import { LazyDialogAuthLoginRegister } from '#components';

const cartStore = useCartStore();
const authStore = useAuthStore();
const { $credova } = useNuxtApp();
const { currency } = useFormatting();
const { screen, dialog, dark } = useQuasar();

const credovaAvailable = computed(
  () =>
    !!cartStore.data && !cartStore.class3
    && $credova.valid(cartStore.data.subTotal),
);

const altMethodsAvailable = computed(() => credovaAvailable.value);

const canShowSubtotal = computed(() =>
  cartStore.data?.items?.every(i => i.canShowPrice),
);

const onCheckoutClick = () => {
  if (authStore.loggedIn) {
    // TODO: check refresh token TTL and possibly take action here
    navigateTo('/checkout');
    return;
  }

  dialog({
    component: LazyDialogAuthLoginRegister,
    componentProps: {
      redirect: '/checkout',
    },
  });
};
</script>
